import React from "react"

const NewIndexPage = () => {
  return (
    <>
      <h1>Test pagina</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In maximus tortor eget congue fermentum. Mauris purus arcu, elementum dignissim ultrices ut, vehicula ac turpis. Phasellus condimentum, felis sit amet sodales interdum, augue felis pellentesque dui, sed posuere massa enim a massa. Nullam ultricies dictum nunc, ullamcorper placerat felis fermentum id. Sed egestas ligula nibh, quis varius felis blandit accumsan. In vestibulum, lectus ut vestibulum gravida, lorem ex vestibulum lacus, non porta nulla velit vehicula quam. Mauris id malesuada tortor. Ut placerat enim id laoreet facilisis. Fusce eget nisi non erat gravida suscipit. Nunc vitae quam vitae ex pulvinar rutrum ut ac tellus. Donec pretium pharetra mi, in vestibulum ante varius tempor. Curabitur tincidunt condimentum enim, venenatis dapibus ligula placerat sed.

        Nam semper vitae justo et interdum. Donec purus nisi, tristique eget luctus in, volutpat tincidunt dui. Integer rutrum tempor libero, congue efficitur neque sodales nec. Pellentesque ac tempor lectus, quis congue turpis. Proin viverra sollicitudin lorem. Pellentesque tempus, mauris vel tempus pretium, erat libero blandit purus, vel euismod nunc erat ac ligula. Nam pretium, mi ut tempor ullamcorper, libero tortor efficitur velit, non condimentum velit magna quis felis. Etiam condimentum euismod commodo. Proin a erat commodo, efficitur nibh in, mattis tortor. Sed dignissim gravida diam, ut imperdiet ex porta et. </p>
    </>
  )
}

export default NewIndexPage
